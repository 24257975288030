<template>
  <Panel header="Manager common statistics" :toggleable="true" :collapsed="isCollapsed">
    <div class="datatable-wrapper" v-if="managerCommonStat?.length">
      <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
        <thead class="p-datatable-tbody">
        <tr class="table-opened-info__header-row">
          <th>Manager</th>
          <th>Orders created</th>
          <th>Offers created</th>
        </tr>
        </thead>
        <tbody class="p-datatable-tbody">
        <tr v-for="(statistics, index) of managerCommonStat" :key="index">
          <td :class="{'no-bottom-border': index === managerCommonStat.length - 1}">{{ statistics.name }}</td>
          <td :class="{'no-bottom-border': index === managerCommonStat.length - 1}">{{ statistics.orders_created_count }}</td>
          <td :class="{'no-bottom-border': index === managerCommonStat.length - 1}">-</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else style="text-align: center; padding: 20px;">
      {{ $t('No data available' )}}
    </div>
  </Panel>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";
export default {
  mixins: [ formatMixins ],
  name: 'ManagerCommonPanel',
  props: {
    managerCommonStat: {
      type: Array,
    }
  },
  data: () => {
    return {
      isCollapsed: false,
    }
  },
}
</script>