<template>
  <Panel header="Managers sales" :toggleable="true" :collapsed="isCollapsed">
    <template v-if="managerSales?.length">
      <div v-for="(manager, index) of managerSales"
           :key="index"
           class="datatable-wrapper"
           :class="{'p-mt-5': managerSales.length > 1 && index > 0}">
        <ManagerSalesPanelTable :fromTimestamp="fromTimestamp"
                                :toTimestamp="toTimestamp"
                                :manager="manager"/>
      </div>
    </template>
    <div v-else style="text-align: center; padding: 20px;">
      {{ $t('No data available' )}}
    </div>
  </Panel>
</template>

<script>
// import formatMixins from "@/mixins/formatMixins";
import ManagerSalesPanelTable from "@/pages/statistics/components/ManagerSalesPanelTable";

export default {
  // mixins: [ formatMixins ],
  components: { ManagerSalesPanelTable },
  name: 'ManagerSalesPanel',
  props: {
    managerSales: {
      type: Array,
    },
    fromTimestamp: [Number, null],
    toTimestamp: [Number, null],
  },
  data: () => {
    return {
      showServicePositions: false,
      servicePositions: [],
      showRequestsPositions: false,
      requestsPositions: [],
      showWarehousePositions: false,
      warehousePositions: [],
      showFreePositions: false,
      freePositions: [],
      isCollapsed: false,
    }
  },
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
}
</style>